import { faArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface DesktopLayoutProps {
    topBtn?: boolean
    children?: React.ReactNode
    className?: string
}

const DesktopLayout = ({
    topBtn = false,
    children,
    className = ''
}: DesktopLayoutProps) => (
    <div className={className}>
        <div className="w-full min-h-screen bg-[#f8f4f5] flex justify-center items-center">
            <div className="relative min-h-screen sm:w-[480px] w-full bg-white shadow-[0px_0px_80px_#dea3b268]">
                {children}
            </div>
        </div>

        {topBtn && (
            <button
                onClick={() => window.scrollTo({ top: 0 })} style={{ right: 'calc((100vw - 480px)/2 + 20px)' }}
                className="fixed bottom-[100px] z-10 w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center shadow-lg border">
                <FontAwesomeIcon icon={faArrowUp} className="w-[20px] h-[20px]" />
            </button>
        )}
    </div>
)

export default DesktopLayout