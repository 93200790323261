import React, { useEffect, useState } from 'react';
import { NextPage } from 'next';
import { ProductListItem } from '@components/snippets/ProductList';
import ShopbyUrl from 'shopbyUrls';
import axios from 'axios';
import { headerConfig } from 'src/headerConfig';
import { danakaUrl } from 'util/usedUrls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCDN340 } from 'util/getCDN';
import maskingName from 'util/maskingName';
import Image from 'next/image';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from 'swiper';
import { useRouter } from 'next/router';
import ProductDetailDesktop from '@components/shop/ProductDetailDesktop';
import { faArrowUp, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { ButtonBase, Divider } from '@mui/material';
import Link from 'next/link';
import CustomPopup, { CustomPopupProps } from '@components/snippets/CustomPopup';
import Head from 'next/head';
import DesktopLayout from '@components/snippets/DesktopLayout';

const Home: NextPage = () => {
    const router = useRouter()
    const [productNo, setProductNo] = useState<number | null>(null);
    const [openBanner, setOpenBanner] = useState<boolean>(true);
    const [popupProps, setPopupProps] = useState<CustomPopupProps | null>(null);

    useEffect(() => {
        if (router.isReady && router.query.no) {
            setProductNo(+router.query.no)
        }
    }, [router])

    return (
       <DesktopLayout>
            <Head>
                <title>쑥쑥마켓 - 활동이 쑥쑥 가격은 뚝뚝</title>
                <meta charSet="utf-8" />
                <meta name="description" content="우리 아이사진으로 세상에 단 하나뿐인 기억을 만들어요! 쑥쑥마켓은 함께 만들어가는 우리 모두의 마켓입니다." />
                <meta property="og:image" content="/assets/쑥쑥마켓_공유썸네일.jpg" />
                <meta property="og:title" content="쑥쑥마켓 - 활동이 쑥쑥 가격은 뚝뚝" />
                <meta property="og:description" content="우리 아이사진으로 세상에 단 하나뿐인 기억을 만들어요! 쑥쑥마켓은 함께 만들어가는 우리 모두의 마켓입니다." />
                <meta property="keywords" content="아이 사진,사진 인화,성장앨범,사진앨범,휴대폰케이스,그립톡,등신대,미아방지 키링,사진책,사진자석,손거울,돌답례,어린이집,액자,종이액자,더블액자,우드스탠드,포토팩,하드포토팩,증명사진,어린이집,가랜드,포토 가랜드,마일스톤,성장카드,달력,탁상 달력" />
                <meta name="naver-site-verification" content="7729b913f4d7d1509b3fbd77820565f097fcd81e" />
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
            </Head>

            <h1 className="a11y-hidden">우리아이 사진으로 만드는 세상 하나뿐인 굿즈</h1>

            <>
                {productNo ? (
                    <div className="">
                        <div className="px-2 h-12 sm:w-[480px] w-full fixed z-[100] bg-white flex justify-start items-center">
                            <ButtonBase onClick={() => {
                                setProductNo(null)
                                router.back()
                            }} className="w-[30px] h-[30px] rounded-full">
                                <FontAwesomeIcon icon={faChevronLeft} className="w-[20px] h-[20px]" />
                            </ButtonBase>
                        </div>
                        <div className="pt-12">
                            <ProductDetailDesktop />
                        </div>
                        <div className="p-4 sm:w-[480px] w-full fixed z-[100] bottom-0 bg-white flex justify-start items-center border-t">
                            <ButtonBase 
                                onClick={() => setPopupProps({
                                    opened: true,
                                    onClose: () => setPopupProps(null),
                                    onOk: () => router.push('https://abr.ge/dr2xis'),
                                    children: `쑥쑥찰칵 앱에서 구매하실 수 있어요!\n앱으로 이동할까요? 🧚`,
                                    okText: '구매하러 갈래요!',
                                    cancelText: '괜찮아요'
                                })}
                                className="bg-[#EB5176] w-full py-3 rounded text-white">
                                구매하기
                            </ButtonBase>
                        </div>
                    </div>
                ) : (
                    <div className={`${openBanner ? 'pt-12' : ''} xl:pt-0`}>
                        <Header />
                        <Divider />
                        <Reviews />
                        <Divider />
                        <Goods />
                    </div>
                )}
            </>

            <div 
                style={{ top: '50vh', transform: 'translateY(-50%)', left: 'calc((100vw - 480px)/2 - 300px)' }} 
                className="fixed hidden xl:flex flex-col justify-center items-center">
                <Image src="/assets/ssckmal_holding_gift.png" alt="쑥말이 캐릭터" width={130} height={162} />
                <p className="whitespace-pre-line text-center font-medium text-[22px] font-gosanja mt-2 mb-5 text-[#2C2C2C]">
                    {`지금 가입하고,\n신규가입 쿠폰 받아가세요!`}
                </p>
                <Link href="https://abr.ge/dr2xis" className="bg-[#EB5176] text-white px-6 py-1.5 text-[17px] rounded-md font-medium">지금 가입하기</Link>
            </div>

            <div style={!openBanner ? { display: 'none' } : undefined} className="fixed z-10 top-0 left-1/2 -translate-x-1/2 sm:w-[480px] sm:h-[40px] w-full bg-[#FFD41F] xl:hidden flex justify-between items-center font-gosanja">
                <button onClick={() => router.push('https://abr.ge/dr2xis')} className="flex-1 text-center sm:text-[20px] text-[17px] font-medium">지금 가입하고, 신규가입 쿠폰 받아가세요!</button>
                <button onClick={() => setOpenBanner(false)} className="h-full aspect-square flex justify-center items-center text-[25px]">&times;</button>
            </div>

            <CustomPopup 
                {...popupProps}
                opened={popupProps !== null}
                onClose={popupProps?.onClose ?? (() => setPopupProps(null))}
                onOk={popupProps?.onOk ?? (() => {})}
                okText={popupProps?.okText ?? ''}
                cancelText={popupProps?.cancelText ?? ''}>
                    {popupProps?.children}
            </CustomPopup>
       </DesktopLayout>
    );
}


const Header = () => {
    return (
        <div className="w-full flex justify-start items-center px-4 py-4">
            <Image src="/assets/ssck_logo.png"  alt="쑥쑥찰칵 로고 이미지" width={100} height={33} />
            <h1 className="a11y-hidden">우리아이 사진으로 만드는 세상 하나뿐인 굿즈</h1>
        </div>
    )
}

const Reviews = () => {
    interface IReviewItem {
        name: string,
        accountIdx: number,
        shopId: string,
        productName: string,
        productNo: number,
        score: number,
        contents: string,
        babyMonth: number,
        medias: string[],
        redirectUrl: string
    }
    const [reviews, setReviews] = useState<IReviewItem[] | null>(null);

    const getReviews = async () => {
        const response = await axios.get(`${danakaUrl.review.goods}`)

        setReviews(response.data)
    }

    useEffect(() => {
        getReviews()
    }, [])

    return (
        <section className="my-4">
            <h2 className="font-bold text-[20px] px-4 mb-4">육아 맘들의 포토 리뷰</h2>
            <Swiper 
                slidesPerView={1.5} 
                centeredSlides 
                loop 
                initialSlide={1}
                modules={[Autoplay]}
                autoplay={{
                delay: 2500,
                disableOnInteraction: true,
            }}>
                {reviews?.map((review, i) => (
                    <SwiperSlide key={`active-review-${i}`} className="flex flex-col px-4">
                        {/* 사진 */}
                        <div className="pb-2">
                            {review.medias?.[0] && (<>
                                    <button disabled className="w-full aspect-[242/135] relative rounded-md overflow-hidden" onClick={() => {}}>
                                        <Image fill src={getCDN340(review.medias?.[0])} alt={`상품 리뷰 사진 ${i}`} className="w-full h-full bg-[#D9D9D9] object-cover" />
                                        {review.medias.length >= 2 && <div className="absolute bottom-0 right-0 text-white z-10 bg-[#00000099] pl-1 pr-1.5 pb-0.5 pt-0.25 rounded-tl text-[0.8em] font-medium">+{review.medias.length}</div>}
                                    </button>
                            </>)}
                        </div>

                        <div className="flex justify-between items-center mb-3">
                            {/* 별점 */}
                            <div className="flex justify-center items-center w-fit">
                                <span className="mr-1 font-medium text-[18px]">{review.score}</span>
                                {Array.from({ length: review.score }).map((_, j) => <FontAwesomeIcon key={`review-star-${j}`} icon={faStar} color="#FFD41F" />)}
                            </div>
                            {/* 이름 및 아기 개월 */}
                            <div className="flex justify-center items-center">
                                <span className="text-[11px] text-[#9E9E9E] mr-2">{maskingName(review.name)}</span>
                                {review.babyMonth >= 0 &&  <div className="text-[11px] text-[#EB5176] border border-[#FFDFE7] bg-[#FFF9FA] px-1 rounded-sm">{review.babyMonth}개월</div>}
                            </div>
                        </div>
                        {/* 상품명 */}
                        <div className="mb-1">{review.productName}</div>
                        {/* 내용 */}
                        <div className="line-clamp-5 text-[15px]">{review.contents}</div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

const Goods = () => {
    const [products, setProducts] = useState<any[] | null>(null);

    const getProductList = async () => {
        const sectionNo = '141393'

        const response = await axios.get(
            `${ShopbyUrl.display.sections}/${sectionNo}`,
            {
                headers: { ...headerConfig, version: '1.1' },
                params: { pageNumber: 1, pageSize: 100 }
            }
        )

        setProducts(response.data.products)   
    }

    useEffect(() => {
        getProductList()
    }, [])

    return (
        <section data-id="GOODS_PC" style={{ minHeight: 'calc(100vh - 3rem)' }} className="my-4">
            <h2 className="font-bold text-[20px] px-4 mb-4">우리아이 사진으로 만드는 굿즈</h2> 
            {/* TODO Tab */}
            <ul className="grid grid-flow-row grid-cols-3 gap-4 p-4">
                {products?.map((item, i) => (
                    <ProductListItem 
                        key={`${item.productNo}-${i}`}
                        thumbnail={item.listImageUrls[0]}
                        brandName={item.brandName}
                        productName={item.productName}
                        productNo={item.productNo}
                        salePrice={item.salePrice}
                        immediateDiscountAmt={item.immediateDiscountAmt}
                        couponDiscountAmt={item.couponDiscountAmt}
                    />
                ))}
            </ul>
        </section>
    )
}

export default Home