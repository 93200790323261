import React, { useEffect, useState } from 'react'
import { Lazy, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductInfo from './ProductInfo'
import DiscountInfo from './DiscountInfo'
import ReviewSwiper from './ReviewSwiper'
import ProductDetailInfos from './ProductDetailInfos'
import ProductBaseInfo from './ProductBaseInfo'
import BottomContents from './BottomContents'
import axios from 'axios'
import ShopbyUrl from 'shopbyUrls'
import { headerConfig, headerConfig2 } from 'src/headerConfig'
import { ManageCodeRegex } from "util/regularExpression";
import { ICartCouponArray, IOptions, IPriceInfo, IProductCoupon, IProductDetailInfos } from 'interface/DetailInterface'
import { exceptProduct } from 'src/exceptProduct'
import useSWR from "swr"
import { useRouter } from 'next/router'
import useShopUser from 'libs/useShopUser'
import { useRecoilValue } from 'recoil'
import { reviewSortbyState } from 'states/shop/reviewSortby'
import { IReviewListResponse } from 'interface/ReviewInterface'
import { IOpenTab } from 'interface/ProductsInterface'
import { CustomPopupProps } from '@components/snippets/CustomPopup'
import { IDownloadable } from 'interface/CouponInterface'
import ReviewList, { ClickReviewImgType } from './ReviewList'
import { IReviews } from 'pages/api/shop/review/[productNo]'

interface ProductDetailDesktopProps {

}

const ProductDetailDesktop = ({  }: ProductDetailDesktopProps) => {
    
    const router = useRouter();
    const { userkey } = useShopUser();
    // const { scrollY } = useScroll();
    // const scrollTopRef = useRef<HTMLDivElement>(null)
    
    const [productDetail, setProductDetail] = useState<IProductDetailInfos | null>();
    // const [optionInfo, setOptionInfo] = useState<IOptions>();
    const [productManageInfo, setProductManageInfo] = useState({deepLinkName : '', optionable : ''})
    // const [relatedProducts, setRelatedProducts] = useState<IRelatedProduct[]>()
    const [openTab, setOpenTab] = useState<IOpenTab>({ product : false, delivery : false, refund : false, seller : false})

    // 기기 버전
    // const [appVersion, setAppVersion] = useState();
    // const [notiUpdate, setNotiUpdate] = useState(false);

    // 팝업 state
    const [popupProps, setPopupProps] = useState<CustomPopupProps | null>(null);
    // const [openPopup, setOpenPopup] = useState<boolean>(false)
    // const [openCustomPopup, setOpenCustomPopup] = useState<boolean>(false)
    // const [custompopup, setCustomPopup]= useState<boolean>(false)
    // const [cartCouponPopup, setCartCouponPopup] = useState<boolean>(false)
    // const [popupMsg, setPopupMsg] = useState<string>('')
    // const [openReview, setOpenReview] = useState<boolean>(false)
    
    // 쿠폰 state
    // const [myCoupon, setMyCoupon] = useState<ICartCouponArray[]>([])
    // const [cartCoupon, setCartCoupon] = useState<IProductCoupon[]>([]);
    // const [productCoupons, setProductCoupons] = useState<IProductCoupon[]>([])
    const [popupOpen, setPopupOpen] = useState<{ 
        position : "bottom" | "middle", 
        target : string, 
        open : boolean,
    }>({ position : "bottom", target : "price", open : false})
    const [maxCouponDiscount, setMaxCountDiscount] = useState<IProductCoupon>();

    // 옵션선택 
    // const [isGift, setIsGift] = useState<boolean>(false);
    const [menuTab, setMenuTab] = useState<number>(0)
    const [openOptionBox, setOpenOptionBox] = useState<boolean>(false);

    // 리뷰
    const reviewSortby = useRecoilValue(reviewSortbyState);
    const [reviewResponse, setReviewResponse] = useState<IReviewListResponse | null>(null);
    const [selectedReview, setSelectedReview] = useState<IReviews | null>(null);
    const [activeImgIdx, setActiveImgIdx] = useState<number>(0);

    // const returnApplyDiscount = (productDetail : IProductDetailInfos) =>{
    //     const price = productDetail.price.salePrice - productDetail.price.immediateDiscountAmt;
    //     const findPresentCoupon = downloadable.filter((i:IDownloadable)=> (i.couponName as string).includes('선물'))?.[0]

    //     if(findPresentCoupon.discountInfo.discountAmt){
    //         const discoutAmt = price - findPresentCoupon.discountInfo.discountAmt
    //         const roundPrice = (Math.floor(discoutAmt/ 10)) * 10
    //         return `선물하기 시 ${priceComma(roundPrice)} ❤️`
    //     }
    //     else{
    //         const discountedRate = Math.floor(((price * (findPresentCoupon.discountInfo.discountRate)/100)) /10) * 10 
    //         const roundPrice = price - discountedRate
    //         return `선물하기 시 ${priceComma(roundPrice)}원 ❤️`
    //     }
    // }

    // const openGiftBox = () =>{
    //     setIsGift(true)
    //     setCartCouponPopup(false)
    //     setOpenOptionBox(true)
    // }

    /** ------------ 선물하기 or 구매하기 구분  ------*/
    // const openOptionFunc = (gift : string) =>{
    //     if(gift === 'gift'){
    //         if(downloadable.filter((i:IDownloadable)=> (i.couponName as string).includes('선물')).length > 0 
    //                                 && !exceptProduct.includes(productDetail?.baseInfo.productNo as number)){
    //             // 1. 선물 쿠폰 있을 때. 
    //             setCartCouponPopup(true)
    //         }else{
    //             // 2. 선물 쿠폰 없을 때, 
    //             setIsGift(true)
    //             setOpenOptionBox(true);
    //         }
    //     }
    //     else {
    //         // 3.그냥 구매하기 일 때
    //         setIsGift(false);
    //         setOpenOptionBox(true);
    //     }
    // }

    /** ---------------- 바로 딥링크 / 옵션 선택 후 딥링크  ----------------*/
    // const optionalFunc = (manageCode:string) => {
    //     const match = ManageCodeRegex('option', manageCode)

    //     // 바로 딥링크 
    //     if(!match){
    //         setOpenPopup(true);
    //         setOpenOptionBox(false)
    //     }else{
    //         setOpenOptionBox(true)
    //     }

// }

    /* --------------------------------- 리뷰 받아오기 -------------------------------- */
    const getReviews = async () => {
        const productNo = router.query.no
        const response = await axios.get(`/api/shop/review/${productNo}?sortBy=${reviewSortby}`)

        if (response.status === 204) return null
        return response.data
    }

    /** ---------------- 쿠폰 발급   ----------------*/
    // const handleDownloadCoupon = async(couponNo:number) =>{
        
    //     const result = await axios.post(
    //         `${ShopbyUrl.promotion.downloadCoupon}/${couponNo}/download`, 
    //         { 'channelType' : null}, 
    //         { headers : {
    //             ...headerConfig,
    //             accessToken : userkey
    //         }})
    //     .then( async (res)=>{
    //         setCustomPopup(true)
    //         setPopupMsg('쿠폰발급이 완료되었습니다!')
    //         await getAvailableCoupon(router.query.no as string)
    //         await refreshDownload()
    //     })
    //     .catch(async(e)=>{
    //         if(e.response.data.message.includes('모두 발급')){
    //             setPopupMsg('이미 발급받은 쿠폰입니다!')
    //         }
    //         else if(e.response.data.message.includes('매진')){
    //             setPopupMsg(`쿠폰발급이 모두 매진되었습니다. \n내일 다시 도전해주세요!`)
    //         }
    //         else if(e.response.data.status === 401){
    //             setPopupMsg(`쿠폰발급은 쑥쑥찰칵 회원만 가능합니다!`)
    //         }

    //         setCustomPopup(true)
    //         await getAvailableCoupon(router.query.no as string)
    //         await refreshDownload()
    //     })   
    // }

    /** ---------------- 최저가 계산룰 - 쿠폰 적용 ---------------- */
    // const maxDiscountPrice = (productCoupon:IProductCoupon[], productDetail:IProductDetailInfos) =>{
    //     let discount:IProductCoupon[] = []
        
    //     // 개당 가격으로 할인하는 쿠폰가 -> 배열로 추출 
    //     productCoupon.map((v, i) =>{
    //         const salePrice = productDetail.price.salePrice - productDetail.price.immediateDiscountAmt
    //         const constraint = v.useConstraint?.minSalePrice

    //         if(constraint <= salePrice){
    //             const curDiscount = v.discountInfo.discountRate 
    //                                 ? calcPrice('coupon',productDetail.price, v.discountInfo.discountRate) as number
    //                                 : v.discountInfo.discountAmt as number

    //             discount.push({...v, discountPrice : curDiscount});
    //         }
    //     })

    //     // 최대로 할인해주는 가격 추출
    //     const maxDiscountPrice = discount.reduce((prev, value) =>{ return (prev.discountPrice as number) >= (value.discountPrice as number) ? prev : value })
    //     setMaxCountDiscount(maxDiscountPrice)
    // }

    // const downloadableFetcher = async(url:string) =>{
    //     const result = await axios.get(url, {headers : {...headerConfig, accessToken : userkey}})
    //     const giftCoupon = result.data.filter((i:IDownloadable) => (i.couponName as string).includes('선물') && i.couponType === 'CART')
    //     return giftCoupon
    // }

    // 다운로드 받을 수 있는 쿠폰 조회
    // const { data:downloadable, mutate:refreshDownload} = useSWR( 
    //     userkey ? `${ShopbyUrl.promotion.issuableCoupon}`: null,
    //     downloadableFetcher
    // )

    /** ---------------- 전체 발급 받을 수 있는 쿠폰 조회 ---------------- */
    // const getAvailableCoupon = async(productNo:string) =>{

    //     const resultByProductNo = await axios.get(
    //         `${ShopbyUrl.promotion.issueableByProductNo}/${productNo}/issuable/coupons`,{
    //             headers : { ...headerConfig, accessToken : userkey }
    //     })

    //     const coupons = resultByProductNo.data
    //     {/** --------------------------  장바구니 쿠폰 -------------------------- */}
    //     const cartCoupons = coupons.filter((i:any)=>i.couponType === 'CART')
    //     {/** --------------------------  상품 쿠폰 -------------------------- */}
    //     const productCoupons = coupons.filter((i:any)=>i.couponType !== 'CART')

    //     setProductCoupons(productCoupons)
    //     setCartCoupon(cartCoupons)
    // }

    /* ------------------------ updateLink ------------------------ */
    // const moveToUpdate = () =>{
    //     const link = isAndroid ? 'market://details?id=com.jejememe.daybabyday&hl=ko&gl=US' 
    //                 : isIOS ? ' https://apps.apple.com/kr/app/%EC%91%A5%EC%91%A5%EC%B0%B0%EC%B9%B5/id1509183009' : ''
    //     setCustomPopup(false); 
    //     router.push(link)
    // }

    /* ------------------------ 기기 별 버전 체크 ------------------------ */
    // const checkVersion = (platform:string, aos:string, ios:string, version?:string) =>{
    //     switch(platform){
    //         case 'AOS':
    //             if(Number(aos) > Number(appVersion) || Number(aos) > Number(version)){
    //                 setNotiUpdate(true)
    //                 setPopupMsg(`상품을 구매 하시려면 \n 쑥쑥찰칵을 업데이트 해주세요`)
    //                 setCustomPopup(true)
    //             }  
    //             break;
    //         case 'IOS':
    //             if(Number(ios) > Number(appVersion) || Number(ios) > Number(version)){
    //                 setNotiUpdate(true)
    //                 setPopupMsg(`상품을 구매 하시려면 \n 쑥쑥찰칵을 업데이트 해주세요`)
    //                 setCustomPopup(true)
    //             }  
    //             break;
    //     }
    // }

    // const getBrand = async(productNo:string) =>{
    //     const result = await axios.get(
    //         `${ShopbyUrl.products.products}/${productNo}`, 
    //         { headers : (userkey === "" || !userkey) ? headerConfig2 : { ...headerConfig, accessToken : userkey }})

    //     return result.data.brand?.nameKo
    // }

    // const getRelatedProducts = async(productNo:string) =>{
    //     const related = await axios.get(`
    //         ${ShopbyUrl.products.related}/${productNo}/related-products`,
    //         { headers : headerConfig2})

    //     let relatedProducs:IRelatedProduct[] = [];
    //     await Promise.all(related.data.map( async(v:IRelatedProduct) => { 
    //        let brand = await getBrand(v.productNo)
    //        relatedProducs.push({...v, nameKo : brand})
    //     }))
    //     setRelatedProducts(relatedProducs)
    // }

    /* ------------------------ 상품 옵션 정보 ------------------------ */
    // const getProductOptions = async(productNo:string)=>{
    //     const inputOption = await axios.get(
    //     `${ShopbyUrl.products.productOption}/${productNo}/options`, 
    //     { headers : (userkey === "" || !userkey) ? headerConfig2 : { ...headerConfig, accessToken : userkey }})

    //     setOptionInfo(inputOption.data)
    // } 

    /* ------------------------ 상품 정보 ------------------------ */
    const getProductDetail = async(productNo:string, version?:string) =>{
        const result = await axios.get(
            `${ShopbyUrl.products.products}/${productNo}`, 
            { headers : headerConfig })
        // 상품 관리코드 -> 굿즈 식별용도
        // const manageCode = result.data.baseInfo.productManagementCd ?? null
        // 사업자 번호가 제제미미인 경우만 버전 체크를 진행한다
        // if(manageCode && result.data.partner.businessRegistrationNo === "4348101803"){

        //     const splitedManageCd = manageCode.split('_')
        //     // 1. 앱 버전 
        //     const isCnt = splitedManageCd[splitedManageCd.length -1].includes('cnt')
        //     const platform = isAndroid ? 'AOS' : isIOS ?'IOS' : 'PC'
        //     let aos, ios;

        //     if(isCnt){
        //         aos = splitedManageCd[splitedManageCd.length -3]
        //         ios = splitedManageCd[splitedManageCd.length -2]
        //     }
        //     else {
        //         aos = splitedManageCd[splitedManageCd.length -2]
        //         ios = splitedManageCd[splitedManageCd.length -1]
        //     }

        //     checkVersion(platform, aos, ios, version)

        //     // 2. 딥링크 설정
        //     const deepLinkName = splitedManageCd?.[1] ?? ''
        //     const optionable = splitedManageCd?.[2] ?? ''

        //     setProductManageInfo(prev=>{return {...prev, deepLinkName : deepLinkName, optionable : optionable}})

        // }
        setProductDetail(result.data)

        // 에어브릿지 - 상세페이지 view
        // airbridge.trackEvent('airbridge.ecommerce.product.viewed', {
        //     'action' : ManageCodeRegex('goods', manageCode) ? 'goodsProduct' : 'commerceProduct',
        //     'label' : `${result.data.baseInfo.productNo}`,
        //     'value' : `${result.data.price.salePrice - result.data.price.immediateDiscountAmt}`,
        //     'semanticAttributes' : {
        //         'products' : [{
        //             'productID' : `${result.data.baseInfo.productNo}`,
        //             'name' : `${result.data.baseInfo.productName}`,
        //             'categoryName' : `${result.data.categories[0]?.fullCategoryLabel}`,
        //             'brandID' : `${result.data.brand ? result.data.brand?.brandNo : null}`, 
        //             'brandName' : `${result.data.brand ? result.data.brand?.nameKo : null}`, 
        //             'price' : `${result.data.price.salePrice - result.data.price.immediateDiscountAmt}`
        //         }]
        //     }
        // })
    }

    /* ---------------------------- 상세 페이지 viewed 기록 ---------------------------- */
    // const viewedProducts = async (no: number) => {
    //     const uk = localStorage.getItem('key')

    //     if (uk === '' || uk === null) return

    //     const response = await axios.get(
    //         `${usedUrl.danaka}/product/viewed/${no}`,
    //         { headers: { "uk": uk } }
    //     )
    // }

    /** ---------------- 총 할인가  ----------------*/
    const totalDiscountPrice = (price:IPriceInfo, coupon:IProductCoupon[], type:string) =>{

        let salePrice = price?.salePrice - price?.immediateDiscountAmt as number
        let discountedPrice;
        let appliedProductCoupon = maxCouponDiscount?.discountPrice ?? 0
        let appliedCartCoupon = 0

        // 굿즈 여부 체크 
        const isGoods = ManageCodeRegex('goods', productDetail?.baseInfo?.productManagementCd as string)

        // if(!exceptProduct.includes(productDetail?.baseInfo?.productNo as number)){
        //     appliedCartCoupon = isGoods && downloadable?.[0]?.couponName.includes('선물')
        //                         ? 0
        //                         : downloadable?.[0]?.discountInfo?.dicountAmt 
        //                         ? downloadable?.[0]?.discountInfo?.dicountAmt 
        //                         : downloadable?.[0]?.discountInfo?.discountRate 
        //                         ? (Math.floor((salePrice * ((downloadable?.[0]?.discountInfo?.discountRate )/100 ))/ 10)) * 10 
        //                         : 0
        // }
        // 적립금
        const cheese = Math.ceil((salePrice as number) * 0.01 / 10) * 10
        
        switch(type){
            case 'amt':
                discountedPrice = appliedProductCoupon + appliedCartCoupon + cheese + price?.immediateDiscountAmt
                break;
            case 'sale' :
                discountedPrice = salePrice - (appliedProductCoupon+ appliedCartCoupon + cheese)
        }

        return discountedPrice
    }

    /** ---------------- 가격계산  ----------------*/
    const calcPrice = (type:string, price:IPriceInfo, rate?:number) =>{
        let calcedPrice;
        const saleAmt = price?.salePrice - price?.immediateDiscountAmt

        switch(type){
            case 'coupon' : // 쿠폰 적용가 
                calcedPrice = Math.floor(((saleAmt as number) * ((rate as number)/ 100)/ 10)) * 10
                break;
            case 'cheese' : // 적립치즈
                calcedPrice = Math.ceil((saleAmt as number) * 0.01 / 10)
                break;
            case 'cheeseToWon': // 적립금
                calcedPrice = Math.ceil((saleAmt as number) * 0.01 / 10) * 10
                break;
        }
    
        return calcedPrice
    }

    // useEffect(()=>{
    //     if(productCoupons.length > 0 && productDetail ){
    //         maxDiscountPrice(productCoupons, productDetail)
    //     }
    // },[productCoupons, productDetail])

    useEffect(()=>{
        if( router.isReady ) {
            setProductDetail(null)

            if(router.query.no){
                getProductDetail(router.query.no as string)
                // getProductOptions(router.query.no as string)
                // getAvailableCoupon(router.query.no as string)
                // getRelatedProducts(router.query.no as string)
                
                getReviews()
                    .then(r => setReviewResponse(r))
                    .catch(() => setReviewResponse(null))
            }
                // viewedProducts(+(router.query.no as string))

                // if (scrollTopRef.current) {
                //     scrollTopRef.current.scrollIntoView({ block : 'end'})
                // }
            //  else if(!router.query.no){
            //     //scrollTo({ top : Number(`${sessionStorage.getItem(`home_${router.asPath}`)}`) })

            //     return scrollY.onChange( y => {
            //         sessionStorage.setItem(`home_${router.asPath}`, `${y}`)
            //     })
            // }
            setMenuTab(0)
        }
    
    },[router.query])

    // useEffect(()=>{
    //     if(router.events){
    //         event({
    //           category : '구매흐름',
    //           action : '진입',
    //           label : `${localStorage.getItem('key')} - 일반상품 상세`,
    //           value : 3
    //         })
    //       }
    // },[router.events])

    const handleClickReviewImg: ClickReviewImgType = (reviewId: string, mediaIdx: number) => {
        setSelectedReview((reviewResponse as IReviewListResponse).reviews.find(r => r.orderNo === reviewId) || null)
        setActiveImgIdx(mediaIdx)
        router.push({ query: { ...router.query, reviews: 'open', review: reviewId }}, undefined, { shallow: true })
    }


    return (
        <div className="w-full">
            {/* 장바구니 팝업 */}
            {/* <CustomPopup
                opened={openCustomPopup}
                okText={'쇼핑하기'}
                onOk={()=>setOpenCustomPopup(false)}
                cancelText={'장바구니 이동'}
                onClose={() => {router.push('/shop/mypage/cart')}}
                paddingCustom={4}
            >
                {`장바구니에 상품이 \n 정상적으로 담겼습니다.`}
            </CustomPopup> */}

            {/* 커스텀 팝업 */}
            {/* <CustomPopup
                opened={custompopup}
                onClose={
                    notiUpdate
                    ? () => { setCustomPopup(false); router.back();}
                    : ()=>setCustomPopup(false)
                }
                onOk={
                    notiUpdate
                    ? () => moveToUpdate()
                    : ()=>setCustomPopup(false)
                }
                cancelText={notiUpdate ? '돌아가기' : '닫기'}
                okText={notiUpdate ? '업데이트' : '확인'}
                oneButton={!notiUpdate}
                >
                {popupMsg}
            </CustomPopup> */}

            {/* 선물하기 쿠폰 용 팝업 */}
            {/* {cartCouponPopup && (
                <CouponPopup
                    closeFunc={()=>{setCartCouponPopup(false)}}
                    handleDownloadCoupon={handleDownloadCoupon}
                    myCoupon={myCoupon}
                    cartCoupon={downloadable}
                    openGiftBox={openGiftBox}
                />
            )} */}

            {/* ------------------------------ 새로만들기 / 기존데이터로 구매하기 ------------------------------ */}
            {/* {openPopup && productDetail &&(
                <ReorderPopup
                    setOpenPopup={setOpenPopup}
                    setOpenCustomPopup={setOpenCustomPopup}
                    productManageInfo={productManageInfo}
                    productDetail={productDetail}
                    setOpenOptionBox={setOpenOptionBox}
                />
            )} */}

            {/* TODO */}
            <div className='w-full aspect-square'>
                {/* 등록된 이미지 썸네일 */}
                <Swiper
                    lazy={true}
                    pagination={true}
                    modules={[Pagination, Lazy]}
                    slidesPerView={1}>
                        
                        {/* TODO */}
                    {productDetail && productDetail.baseInfo.imageUrls.map((item, idx)=>{
                        return (
                        <SwiperSlide key={idx} className="w-full aspect-square overflow-hidden">
                            { idx === 0
                            ? <img className="w-full swiper-lazy" src={item} />
                            : (<>
                                <img className="w-full swiper-lazy" loading='lazy' data-src={item} />
                                <div className='swiper-lazy-preloader swiper-lazy-preloader-white'></div>
                            </>)}
                        </SwiperSlide>)
                    })}
                </Swiper>
            </div>

            {/** TODO 상품 기본 정보 */}
            <ProductInfo 
                productCoupons={[]}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
                productDetail={productDetail}
                reviews={reviewResponse}
                disabledReviewOpen
            />

            {/* TODO 할인가격 대시보드 */}
            {productDetail && productDetail.price.salePrice > 1000 &&
                <DiscountInfo
                    productCoupons={[]}
                    cartCoupons={[]}
                    productDetail={productDetail}
                    totalDiscountPrice={totalDiscountPrice}
                    calcPrice={calcPrice}
                    maxCouponDiscount={maxCouponDiscount}
                    />}

            {/* 리뷰  */}
            <ReviewSwiper reviews={reviewResponse} onClickReviewImg={() => {}} disabledOpen/>

            {/** 상품 상세 정보 */}
            <ProductDetailInfos
            // optionInfo={optionInfo}
            productDetail={productDetail}
            reviews={reviewResponse} />

            {/** 연관상품 */}
            {/* <RelatedProducts type="common" title="함께 사면 더 저렴해요!" items={relatedProducts}/> */}

            {/** 상품 기본정보 */}
            <ProductBaseInfo 
                openTab={openTab}
                setOpenTab={setOpenTab}
                productDetail={productDetail}/>


            {/** dimmed 처리 */}
            {openOptionBox && (<div className='fixed top-0 h-screen w-full bg-[rgba(0,0,0,0.41)] z-10' onClick={(prev)=>setOpenOptionBox(!prev)}></div>)}

            {/* {downloadable && downloadable.length > 0 && downloadable.find((i:IDownloadable) => (i.couponName as string).includes('선물'))
            && productDetail
            && !exceptProduct.includes(productDetail.baseInfo.productNo) // 임시코드 -> 상품쿠폰 적용하면 안되는 상품조건 처리
            && !ManageCodeRegex('goods', productDetail.baseInfo.productManagementCd )
            && !ManageCodeRegex('later', productDetail.baseInfo.productManagementCd)
            ? (<Tooltip
                tipMsg={returnApplyDiscount(productDetail as IProductDetailInfos)}
                bgColor="#635348"
                textColor="white"
                tailDirection="bottom-tail"
                position="fixed bottom-[80px] left-[10%] z-[20]"
                />)
            : null} */}

            {/* {openOptionBox  && productDetail && optionInfo &&(
                <OptionBox
                    isGift={isGift}
                    productDetail={productDetail}
                    optionInfo={optionInfo}
                    productManageInfo={productManageInfo}
                    extraFunc={()=>{}}
                    setOpenOptionBox={setOpenOptionBox}
                />
            )} */}

                {/* {productDetail && ManageCodeRegex('later', productDetail.baseInfo.productManagementCd)
                ? (<div className='fixed bottom-0 w-full z-[2]'>
                    <OneButton
                        buttonTitle='구매 하기'
                        handleClickFunction={()=>openOptionFunc('buy')}
                        bgColor={"#EB5176"}
                        textColor={"white"}
                    />
                </div>) 
                : productDetail && ManageCodeRegex('goods', productDetail.baseInfo.productManagementCd)
                ? (<div className='fixed bottom-0 w-full z-[2]'>
                    <OneButton
                        buttonTitle={"구매 하기"}
                        bgColor={"#EB5176"}
                        textColor={"white"}
                        handleClickFunction={() => optionalFunc(productDetail.baseInfo.productManagementCd)}
                    />
                </div>)
                : (<ShopFooter
                    LeftText="선물하기"
                    leftBtnFunc={()=>openOptionFunc('gift')}
                    rightText="구매하기"
                    rightBtnFunc={()=>openOptionFunc('buy')}
                />)} */}

                
            <BottomContents productDetail={productDetail}/>
        </div>
    )
}

export default ProductDetailDesktop
