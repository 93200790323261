import { IDownloadable } from "interface/CouponInterface"
import { IPriceInfo } from "interface/DetailInterface"
import { IProductCoupon, IProductDetailInfos } from "interface/DetailInterface"
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { exceptProduct } from "src/exceptProduct"
import { myCheeseState } from "states/shop/myCheese"
import { priceComma } from "util/priceComma"
import { ManageCodeRegex } from "util/regularExpression"

interface DiscountInfoProps {
    productCoupons : IProductCoupon[],
    cartCoupons : IDownloadable[],
    productDetail : IProductDetailInfos | null | undefined
    totalDiscountPrice : any,
    maxCouponDiscount : IProductCoupon | undefined, 
    calcPrice : (type: string, price: IPriceInfo, rate?: number) => number | undefined
}


const DiscountInfo = ({productCoupons, cartCoupons, productDetail, totalDiscountPrice, maxCouponDiscount , calcPrice} : DiscountInfoProps) =>{

    const [myCheese] = useRecoilState(myCheeseState)

    return (

            <div className="border-[1px] border-[#EB5176] bg-[#FFF9FA] mx-4 mb-6 rounded-md px-4 py-6 font-noto font-14">
                {/* 최저가 계산기 */}
                <div className="font-[700] mb-1">최저가 계산기 🧮</div>

                {productDetail && productCoupons && (<>
                    {!totalDiscountPrice(productDetail?.price, productCoupons, 'sale', myCheese)
                    ? (<span>공짜 🥳</span>)
                    : (<>
                        <span>쑥쑥마켓이 정상가 대비 </span>
                        <span className="font-bold text-[#EE6686]">{priceComma(totalDiscountPrice(productDetail?.price, productCoupons, 'amt', myCheese))}</span>
                        <span>원 더 저렴해요!</span>
                    </>)}
                </>)}

                {/* 쿠폰할인가 */}
                {maxCouponDiscount && productDetail && (<>
                    <div className="font-[700] mt-4 mb-1">쿠폰 할인</div>

                    <div className="flex justify-between">
                        <span>{maxCouponDiscount.couponName}</span>
                        <span>
                        {maxCouponDiscount.discountInfo.discountAmt && productDetail
                            ? `-${priceComma(maxCouponDiscount.discountInfo.discountAmt)}원`
                            : `-${priceComma(calcPrice('coupon',productDetail.price as IPriceInfo, maxCouponDiscount.discountInfo.discountRate) as number)}원`}
                        </span>
                    </div>
                </>)}


                {/* 쿠폰 할인가  */}
                {productDetail && productCoupons.length > 0 &&
                (<>
                    <div className="font-[700] mt-4 mb-1">더 많이 구매 or 장바구니쿠폰 적용 시</div>

                    {/* 상품쿠폰 */}
                    {productCoupons.filter((i) => i.couponNo !== maxCouponDiscount?.couponNo).map((v, i)=>{
                        return (
                            <div key={v.couponNo + i} className="mb-2">
                                <div>
                                    <span>
                                        {productDetail.price.salePrice - productDetail.price.immediateDiscountAmt <= v.useConstraint.minSalePrice
                                        ? `${Math.ceil(v.useConstraint.minSalePrice / (productDetail.price.salePrice - productDetail.price.immediateDiscountAmt))}개 이상 구매시, `
                                        : ''}
                                    </span>

                                    <span>
                                        {v.discountInfo.discountAmt && productDetail
                                        ? `${priceComma(v.discountInfo.discountAmt)}원 할인`
                                        : `${priceComma(calcPrice('coupon',productDetail.price as IPriceInfo,v.discountInfo.discountRate) as number)}원 할인`}
                                    </span>
                                </div>

                                <span>└ {v.couponName} 적용가능</span>
                            </div>
                        )
                    })}
                </>)}

                {cartCoupons 
                    && productDetail 
                    && !exceptProduct.includes(productDetail.baseInfo.productNo) 
                    && ( !ManageCodeRegex('goods', `${productDetail.baseInfo.productManagementCd}`) && cartCoupons?.[0]?.couponName?.includes('선물')) 
                    &&(<>
                    {!maxCouponDiscount && <div className="font-[700] mt-4 mb-1">쿠폰 할인</div>}

                    <div className="flex justify-between">
                        <span>{cartCoupons?.[0]?.couponName}</span>
                        <span>
                        {cartCoupons?.[0]?.discountInfo?.discountAmt 
                        ? priceComma(cartCoupons?.[0]?.discountInfo?.discountAmt) 
                        : priceComma(cartCoupons?.[0]?.discountInfo?.discountRate) 
                        ? priceComma((Math.floor(((productDetail.price.salePrice - productDetail.price.immediateDiscountAmt) * ((cartCoupons?.[0]?.discountInfo?.discountRate )/100 ))/ 10)) * 10) : 0}원
                    </span>
                    </div>
                </>)}

                {/* 구분선 */}
                {/* <div className="border-b-[0.5px] border-[#EB5176] border-dashed my-4"></div> */}

                {/* 적립 가능 치즈 */}
                {/*&#40;1% 적립&#41; 뺀다*/}
                <div className="font-[700] mt-2 mb-1">적립 가능 치즈</div>

                <div className="flex justify-between">
                    <span>후기 작성시 </span>
                    <div>
                        <span>{`+${calcPrice('cheeseToWon',productDetail?.price as IPriceInfo)}원 `}</span>
                        <span className="text-[0.8em]">&#40;{`${calcPrice('cheese', productDetail?.price as IPriceInfo)}치즈`}&#41;</span>
                    </div>
                </div>
            </div>
    )
}

export default DiscountInfo